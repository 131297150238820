import { getData, toArray } from '../utils';
import BaseComponent from './base-component';

/**
 * @param {HTMLSelectElement|HTMLOptionElement} selectOrOption
 * @param {boolean} isTypeFilter
 * @returns {Array.<string>|number}
 */
function parseOptionValue(selectOrOption, isTypeFilter) {
  // `type` sub items have a `<group>|<type>` value. Select values are always
  // strings, cast to number for years and versions.
  return isTypeFilter
    ? selectOrOption.value.split('|')
    : Number(selectOrOption.value);
}

/**
 * Wrapped filter select <option>.
 */
export class FilterSelectOption {
  /** @type {HTMLOptionElement} */
  elem;

  /** @type {FilterSelect} */
  filterSelect;

  /**
   * @param {HTMLOptionElement} elem
   * @param {FilterSelect} filterSelect
   */
  constructor(elem, filterSelect) {
    this.elem = elem;
    this.filterSelect = filterSelect;
  }

  /**
   * @returns {Array.<string>|number} A tuple of `[<group>, <?type>]` or a
   *   version/year number.
   */
  get value() {
    return parseOptionValue(this.elem, this.filterSelect.isTypeFilter);
  }
}

/**
 * Wrapped filter <select>.
 */
export class FilterSelect {
  /** @type {HTMLSelectElement} */
  elem;

  /** @type {string} */
  filterType;

  /** @param {HTMLSelectElement} elem */
  constructor(elem) {
    this.elem = elem;
    this.filterType = getData(this.elem, 'filter-type');
  }

  get isActive() {
    // The 'all' options have empty values.
    return Boolean(this.elem.value);
  }

  /**
   * If this filter select is for dataset type.
   */
  get isTypeFilter() {
    return this.filterType === 'type';
  }

  /**
   * @returns {Array.<string>|number} A tuple of `[<group>, <?type>]` or a
   *   version/year number.
   */
  get value() {
    return parseOptionValue(this.elem, this.isTypeFilter);
  }

  /** @returns {Array.<FilterSelectOption>} */
  get options() {
    return toArray(this.elem.options).map(
      (opt) => new FilterSelectOption(opt, this)
    );
  }

  enableAllOptions() {
    this.options.forEach((option) => {
      option.elem.disabled = false;
    });
  }
}

/**
 * A single dataset item block.
 */
export class FilterItem {
  /** @type {HTMLElement} */
  elem;

  /** @type {string} */
  group;

  /** @type {string} */
  type;

  /** @type {string} */
  version;

  /** @type {string} */
  year;

  /**
   * @param {HTMLElement} elem
   */
  constructor(elem) {
    this.elem = elem.cloneNode(true);
    this.group = getData(this.elem, 'group');
    this.type = getData(this.elem, 'type');
    this.version = getData(this.elem, 'version');
    this.year = getData(this.elem, 'year');
  }
}

/**
 * Dataset archive filtering.
 */
export default class DatasetArchive extends BaseComponent {
  /** @type {Array.<FilterItem>} */
  index = [];

  /** @type {Array.<FilterItem>} */
  filteredIndex = [];

  /** @type {Array.<FilterSelect>} */
  filterSelects;

  /** @type {HTMLElement} */
  archiveList;

  /** @type {HTMLElement} */
  filterResultContainer;

  /** @type {HTMLElement} */
  filterStatusContainer;

  /** @type {HTMLElement} */
  filterStatusTitle;

  /** @type {HTMLElement} */
  filterItemsContainer;

  /** @type {HTMLButtonElement} */
  filterResetButton;

  init() {
    this.archiveList = this.selectSingle('archive-list');
    this.filterResultContainer = this.selectSingle('filter-result');
    this.filterStatusContainer = this.selectSingle('filter-status');
    this.filterStatusTitle = this.selectSingle('filter-status-title');
    this.filterItemsContainer = this.selectSingle('filter-items');
    this.filterResetButton = this.selectSingle('filter-reset');

    this.filterSelects = this.selectAll('filter-select').map(
      (elem) => new FilterSelect(elem)
    );
    this.index = this.selectAll('dataset', this.archiveList).map(
      (elem) => new FilterItem(elem)
    );

    this.resetFilteredIndex();
    this.bindEvents();
    // Filter on load since some browsers keep form state across refreshes.
    this.filter();
  }

  get activeFilterSelects() {
    // The 'all' options have empty values.
    return this.filterSelects.filter((select) => select.isActive);
  }

  get hasActiveFilter() {
    return this.activeFilterSelects.length > 0;
  }

  showFilterResult() {
    this.filterStatusContainer.classList.remove('visuallyhidden');
    this.filterResultContainer.removeAttribute('hidden');
    this.archiveList.setAttribute('hidden', '');
  }

  hideFilterResult() {
    this.filterStatusContainer.classList.add('visuallyhidden');
    this.filterResultContainer.setAttribute('hidden', '');
    this.archiveList.removeAttribute('hidden');
  }

  /**
   * Set the filter result text depending on number of results.
   */
  setFilterStatusText() {
    const visibleCount = this.filteredIndex.length;
    const totalCount = this.index.length;
    const statusTextKey =
      // eslint-disable-next-line no-nested-ternary
      visibleCount === totalCount
        ? 'all-text'
        : visibleCount === 0
        ? 'none-text'
        : 'partial-text';
    this.filterStatusTitle.textContent = getData(
      this.filterStatusTitle,
      statusTextKey
    )
      .replace('{count}', visibleCount)
      .replace('{total}', totalCount);
  }

  /**
   * Do the filtering based on selected filter values.
   */
  filter() {
    this.filterItemsContainer.textContent = '';

    if (this.hasActiveFilter) {
      this.filteredIndex = this.index.filter((item) =>
        this.activeFilterSelects.every((select) => {
          const { value } = select;
          if (select.isTypeFilter) {
            const [group, type] = value;
            return type
              ? item.type === type && item.group === group
              : item.group === group;
          }
          return item[select.filterType] === value;
        })
      );
      this.filterItemsContainer.append(
        ...this.filteredIndex.map((item) => item.elem)
      );

      this.setFilterStatusText();
      this.showFilterResult();
    } else {
      this.resetFilteredIndex();
      this.setFilterStatusText();
      this.hideFilterResult();
    }

    this.filterResetButton.disabled = !this.hasActiveFilter;
  }

  /**
   * Reset the filtered items collection to contain all items.
   */
  resetFilteredIndex() {
    this.filteredIndex = [...this.index];
  }

  /**
   * Set all filter <select> controls to their initial values.
   */
  resetFilterSelects() {
    this.filterSelects.forEach((select) => {
      select.elem.value = '';
      select.enableAllOptions();
    });
  }

  handleFilterSelectChange = () => {
    this.filter();
  };

  handleResetClick = () => {
    this.resetFilterSelects();
    this.filter();
  };

  bindEvents() {
    this.filterSelects.forEach((select) => {
      select.elem.addEventListener('change', this.handleFilterSelectChange);
    });
    this.filterResetButton.addEventListener('click', this.handleResetClick);
  }
}
