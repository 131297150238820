/**
 * Fix the accessibility of internal hash links.
 *
 * - https://axesslab.com/skip-links/
 * - https://www.nczonline.net/blog/2013/01/15/fixing-skip-to-content-links/
 */
export default function fixSkiplinks() {
  const interactiveElementRegex = /^(?:a|select|input|button|textarea)$/i;

  /**
   * Set focus to the element currently linked to.
   */
  function focusLocationHash() {
    const id = window.location.hash.substring(1);
    if (id) {
      const el = document.getElementById(id);
      if (el) {
        // Don't override existing tabindex. Unset defaults to -1 when getting
        // but that isn't the same as an explicit attribute value of -1.
        if (!interactiveElementRegex.test(el.tagName) && el.tabIndex < 0) {
          el.tabIndex = -1;
        }
        el.focus();
      }
    }
  }

  // Firefox and Chrome do the right thing. Possibly others, haven't tested.
  const ua = navigator.userAgent.toLowerCase();
  if (!ua.includes('firefox') && !ua.includes('chrome')) {
    // Run directly on page load to set focus if there is a hash in the URL
    focusLocationHash();
    window.addEventListener('hashchange', focusLocationHash);
  }
}
