import { getData, hasOwnProp, onLoad, onReady, selectAll } from './utils';

/**
 * Handle instances of component classes.
 *
 * Simple system where a component class instance is created for each element
 * with a `data-component="<component name>"` attribute. Each class' `init`
 * and `load` methods are called on DOM ready and window load respectively.
 * An element can have multiple components, each name separated by a space.
 */
class ComponentManager {
  /** @type {Array.<object>} */
  instances = [];

  /** @type {Object.<string, Function>} */
  componentMap = {};

  /**
   * Register available components.
   *
   * @param {object} componentMap - Keys are `data-component` names, values are
   *   the component classes.
   * @returns {object} The manager instance.
   */
  register(componentMap) {
    Object.assign(this.componentMap, componentMap);
    return this;
  }

  /**
   * Initialize all registered components.
   */
  init() {
    onReady(this._ready);
    onLoad(this._load);
  }

  /**
   * Get the component instance of the specified class for a DOM node.
   *
   * @param {HTMLElement} element
   * @param {Function} componentClass
   * @returns {?object} Found instance or null.
   */
  getInstanceForElement(element, componentClass) {
    return this.instances.find(
      (instance) =>
        instance.root === element && instance.constructor === componentClass
    );
  }

  _ready = () => {
    // Init all components
    selectAll('[data-component]').forEach((elem) => {
      String(getData(elem, 'component'))
        .split(' ')
        .forEach((componentId) => {
          if (!hasOwnProp(this.componentMap, componentId)) {
            throw new Error(
              `Found element with unknown data-component '${componentId}'`
            );
          }
          const ComponentClass = this.componentMap[componentId];
          const instance = new ComponentClass(elem);
          instance.init();
          this.instances.push(instance);
        });
    });
  };

  _load = () => {
    this.instances.forEach((instance) => {
      instance.load();
    });
  };
}

const manager = new ComponentManager();

export default manager;
