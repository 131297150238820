import {
  asArray,
  getData,
  isEmpty,
  NBSP,
  removeData,
  removePrefix,
  selectAll,
  setData,
} from '../utils';
import BaseComponent from './base-component';

const FORM_ERROR_MESSAGE_CLASS = 'form-error';
const FORM_SUCCESS_MESSAGE_CLASS = 'form-success';
const FIELD_ERROR_MESSAGE_CLASS = 'field-error';

/**
 * A single form field.
 */
export class Field {
  /** @type {HTMLElement} */
  container;

  /** @type {Array.<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>} */
  elements;

  /** @type {string} */
  baseName;

  /**
   * Not necessarily the same as an input's `type`.
   *
   * @type {string}
   */
  fieldType;

  /** @type {HTMLElement} */
  requiredStar;

  /** @type {HTMLElement} */
  error;

  /**
   * @param {HTMLElement} container
   * @param {string} [formPrefix]
   */
  constructor(container, formPrefix = '') {
    this.container = container;
    // Radios and checkboxes can be grouped under a single field.
    this.elements = selectAll('input,select,textarea', container);
    this.baseName = formPrefix
      ? removePrefix(this.elements[0].name, formPrefix)
      : this.elements[0].name;
    this.fieldType = getData(this.container, 'field-type');
    this.requiredStar = container.querySelector('.required-star');
    this.error = container.querySelector(`.${FIELD_ERROR_MESSAGE_CLASS}`);
  }
}

/**
 * Send forms via Ajax and handle validation messages.
 */
export default class Form extends BaseComponent {
  /** @type {HTMLFormElement} */
  form;

  /** @type {string} */
  formPrefix = '';

  /** @type {boolean} */
  isAjax = true;

  /** @type {HTMLElement} */
  formMessageAfter;

  /** @type {Array.<Field>} */
  fields = [];

  /**
   * @private
   * @type {Array.<Function>}
   */
  errorCallbacks = [];

  /**
   * @private
   * @type {Array.<Function>}
   */
  successCallbacks = [];

  /**
   * Container for screen reader announcements.
   *
   * @type {?HTMLElement}
   */
  submittingStatus;

  /** @type {HTMLInputElement} */
  submitButton;

  /** @type {number} */
  clearMessagesTimer;

  init() {
    this.form = this.root;
    this.formPrefix = getData(this.form, 'prefix');
    this.isAjax = getData(this.form, 'ajax');
    this.formMessageAfter = this.selectSingle('[role="status"]', this.form);
    this.fields = this.selectAll('.form__field').map(
      (fieldContainer) => new Field(fieldContainer, this.formPrefix)
    );
    this.submittingStatus = this.selectSingle('submitting-status');
    this.submitButton = this.selectSingle('button[type="submit"]');

    this.bindEvents();
  }

  /**
   * Get a Field by name.
   *
   * @param {string} name - Field name with or without form prefix.
   * @returns {?Field}
   */
  getField = (name) =>
    this.fields.find((field) =>
      field.elements.some(
        (el) => el.name === name || el.name === `${this.formPrefix}${name}`
      )
    );

  /**
   * Add a function to be called when the form responds with errors.
   * Receives the form response data.
   *
   * @param {Function} callback
   */
  addErrorCallback = (callback) => {
    this.errorCallbacks.push(callback);
  };

  /**
   * Add a function to be called when the form submission was successful.
   * Receives the form response data.
   *
   * @param {Function} callback
   */
  addSuccessCallback = (callback) => {
    this.successCallbacks.push(callback);
  };

  get isSubmitting() {
    return getData(this.form, 'is-submitting', Boolean);
  }

  set isSubmitting(value) {
    if (value) {
      setData(this.form, 'is-submitting');
    } else {
      removeData(this.form, 'is-submitting');
    }
  }

  /**
   * Clear all form and field messages.
   */
  clearMessages() {
    if (this.clearMessagesTimer) {
      clearTimeout(this.clearMessagesTimer);
      this.clearMessagesTimer = null;
    }

    const formMessagesSelector = [
      FORM_ERROR_MESSAGE_CLASS,
      FORM_SUCCESS_MESSAGE_CLASS,
      FIELD_ERROR_MESSAGE_CLASS,
    ]
      .map((cls) => `.${cls}`)
      .join(', ');
    this.selectAll(formMessagesSelector).forEach((node) => {
      setData(node, 'empty', '');
      node.textContent = NBSP;
    });
    this.selectAll('[aria-invalid]').forEach((node) => {
      node.setAttribute('aria-invalid', 'false');
    });
  }

  /**
   * @private
   * @param {string} className
   * @returns {HTMLDivElement}
   */
  makeContainer(className) {
    const div = document.createElement('div');
    div.classList.add(className);
    return div;
  }

  /**
   * @private
   * @param {string} text
   * @returns {HTMLParagraphElement}
   */
  makeP(text) {
    const p = document.createElement('p');
    p.textContent = text;
    return p;
  }

  /**
   * @param {HTMLElement} node
   * @param {string|Array.<string>} errors
   */
  setErrorContent(node, errors) {
    node.textContent = '';
    node.append(...asArray(errors).map((msg) => this.makeP(msg)));
    removeData(node, 'empty');
  }

  /**
   * Display form and/or field errors.
   *
   * Handles an object with field names:
   *
   *   { email: ['Enter a valid email'], message: ['A message is required'] }
   *
   * an array of messages:
   *
   *   ['Foo is invalid', 'Bar is too short']
   *
   * and a single message string. It also handles field names with and without
   * a form prefix (the response will always use unprefixed names).
   *
   * @param {string|Array.<string>|object} errors
   */
  showErrors(errors) {
    // Remove any existing to avoid stacking.
    this.clearMessages();

    const formErrorsContainer = this.makeContainer(FORM_ERROR_MESSAGE_CLASS);
    formErrorsContainer.setAttribute('role', 'alert');

    if (Array.isArray(errors) || typeof errors === 'string') {
      this.setErrorContent(formErrorsContainer, errors);
    } else if (typeof errors === 'object') {
      Object.entries(errors).forEach(([fieldName, fieldErrors]) => {
        const field = this.getField(fieldName);
        if (field) {
          // https://adrianroselli.com/2022/02/support-for-marking-radio-buttons-required-invalid.html
          const errorAttrTarget =
            field.fieldType === 'radioselect'
              ? field.elements[0].closest('fieldset')
              : field.elements[0];
          errorAttrTarget.setAttribute('aria-invalid', 'true');
          this.setErrorContent(field.error, fieldErrors);
        } else {
          // fieldName can be something like '__all__' when the error belongs
          // to the entire form.
          this.setErrorContent(formErrorsContainer, fieldErrors);
        }
      });
    } else {
      throw new TypeError('Unknown errors format');
    }

    if (formErrorsContainer.childNodes.length) {
      this.formMessageAfter.after(formErrorsContainer);
    }
  }

  /**
   * @param {string} message
   */
  showSuccess(message) {
    this.clearMessages();

    if (message) {
      const container = this.makeContainer(FORM_SUCCESS_MESSAGE_CLASS);
      container.setAttribute('role', 'alert');
      container.append(this.makeP(message));
      this.formMessageAfter.after(container);

      this.clearMessagesTimer = setTimeout(() => {
        this.clearMessages();
      }, 7000);
    }
  }

  /**
   * Set the form as currently submitting.
   */
  setSubmittingState() {
    this.isSubmitting = true;
    if (this.submittingStatus) {
      this.submittingStatus.textContent = getData(
        this.submittingStatus,
        'text'
      );
    }
    // Hide text and show a spinner while keeping the same size.
    const btn = this.submitButton;
    btn.style.width = `${btn.offsetWidth}px`;
    btn.innerHTML = `<span class="spinner"></span><span class="visuallyhidden">${btn.innerHTML}</span>`;
  }

  /**
   * Remove all currently submitting state.
   */
  clearSubmittingState() {
    this.isSubmitting = false;
    if (this.submittingStatus) {
      this.submittingStatus.textContent = '';
    }
    const btn = this.submitButton;
    btn.innerHTML = this.selectSingle('.visuallyhidden', btn).innerHTML;
    btn.style.removeProperty('width');
  }

  /** @private */
  runErrorCallbacks(formResponse) {
    this.errorCallbacks.forEach((callback) => {
      callback(formResponse);
    });
  }

  /** @private */
  runSuccessCallbacks(formResponse) {
    this.successCallbacks.forEach((callback) => {
      callback(formResponse);
    });
  }

  /**
   * Trigger invalid events via native form validation on blur (normally only
   * fired on form submit).
   *
   * @param {FocusEvent} e - Blur event.
   */
  handleFieldBlur = (e) => {
    e.target.classList.add('touched');
    e.target.checkValidity?.();
  };

  /**
   * @param {SubmitEvent} e
   */
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isSubmitting) {
      return;
    }

    this.clearMessages();
    this.setSubmittingState();

    let response = {};
    fetch(this.form.action, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: new FormData(this.form),
    })
      .then((rawResponse) => rawResponse.json())
      .then((json) => {
        /* eslint-disable camelcase */

        response = json;
        const { errors, success_message } = response;
        if (!isEmpty(errors)) {
          this.showErrors(errors);
          const firstErrorField = this.selectSingle('[aria-invalid="true"]');
          if (firstErrorField) {
            firstErrorField.focus();
          } else {
            this.submitButton.focus();
          }
          this.runErrorCallbacks(response);
        } else {
          if (success_message) {
            this.showSuccess(success_message);
          }
          this.form.reset();
          this.runSuccessCallbacks(response);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        this.showErrors('Unexpected error, please try again later');
        this.runErrorCallbacks(response);
      })
      .finally(() => {
        this.clearSubmittingState();
      });
  };

  bindEvents() {
    this.fields.forEach((field) => {
      field.elements.forEach((el) => {
        el.addEventListener('blur', this.handleFieldBlur);
      });
    });
    if (this.isAjax) {
      this.form.addEventListener('submit', this.handleSubmit);
    }
  }
}
