import { selectAll, selectSingle } from '../utils';

export default class BaseComponent {
  /**
   * DOM node that has `data-component`.
   *
   * @type {HTMLElement}
   */
  root = null;

  /**
   * @param {HTMLElement} rootEl
   */
  constructor(rootEl) {
    this.root = rootEl;
  }

  /**
   * Runs on DOM ready, override in subclass.
   */
  init() {}

  /**
   * Runs on window load, override in subclass.
   */
  load() {}

  /**
   * Get all nodes matching the selector inside a parent.
   *
   * @param {string} selector - CSS selector.
   * @param {Element} [parent] - Parent node, defaults to the component root.
   * @returns {Array.<HTMLElement>} An array of found elements, can be empty.
   */
  selectAll(selector, parent = this.root) {
    return selectAll(selector, parent);
  }

  /**
   * Get all nodes matching the selector inside a parent.
   *
   * @param {string} selector - CSS selector.
   * @param {Element} [parent] - Parent node, defaults to the component root.
   * @returns {?HTMLElement} Found element or null.
   */
  selectSingle(selector, parent = this.root) {
    return selectSingle(selector, parent);
  }
}
