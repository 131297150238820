/*
eslint-disable
func-names,
no-bitwise,
no-extend-native,
no-param-reassign,
no-prototype-builtins,
no-restricted-globals,
no-restricted-syntax,
no-var,
object-shorthand,
prefer-arrow-callback,
prefer-destructuring,
prefer-rest-params,
vars-on-top,
*/

// Manually polyfilling rather than using core-js via @babel/preset-env's
// `usage` for `useBuiltIns`. core-js includes bugfixes for edge cases in
// modern browsers as well as IE8 support for some features, both are things
// that bloat the bundle size significantly.
//
// For example, compiling a source file that only contains
//
//     window.addEventListener('load', () => /a/.test('b'));
//
// will result in almost 1400 lines of polyfill code.
//
// Try to keep track of what's missing from IE11 instead and add simpler
// polyfills, e.g. from https://vanillajstoolkit.com/polyfills/ and/or MDN.

import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';

// Variables to get mangled in minification.
var objectProto = Object.prototype;
var stringProto = String.prototype;
var arrayProto = Array.prototype;
var nodeListProto = NodeList.prototype;
var elementProto = Element.prototype;
var documentFragmentProto = DocumentFragment.prototype;
var mediaQueryListProto = MediaQueryList.prototype;

function hasOwnProp(obj, prop) {
  return objectProto.hasOwnProperty.call(obj, prop);
}

function toArray(val) {
  return arrayProto.slice.call(val);
}

// Object.keys
// ----------------------------------------------------------------------------
if (!Object.keys) {
  Object.keys = (function () {
    var hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString');
    var dontEnums = [
      'toString',
      'toLocaleString',
      'valueOf',
      'hasOwnProperty',
      'isPrototypeOf',
      'propertyIsEnumerable',
      'constructor',
    ];
    var dontEnumsLength = dontEnums.length;

    return function (obj) {
      if (
        typeof obj !== 'function' &&
        (typeof obj !== 'object' || obj === null)
      ) {
        throw new TypeError('Object.keys called on non-object');
      }

      var result = [];
      var prop;
      var i;

      for (prop in obj) {
        if (hasOwnProp(obj, prop)) {
          result.push(prop);
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProp(obj, dontEnums[i])) {
            result.push(dontEnums[i]);
          }
        }
      }
      return result;
    };
  })();
}

// Object.values
// ----------------------------------------------------------------------------
if (!Object.values) {
  Object.values = function (obj) {
    return Object.keys(obj).map(function (e) {
      return obj[e];
    });
  };
}

// Object.entries
// ----------------------------------------------------------------------------
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj);
    var i = ownProps.length;
    var resArray = new Array(i);

    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

// Object.assign
// ----------------------------------------------------------------------------
if (!Object.assign) {
  // .length of function is 2
  // eslint-disable-next-line no-unused-vars
  Object.assign = function assign(target, varArgs) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    var to = Object(target);
    toArray(arguments).forEach(function (nextSource) {
      // Skip over if undefined or null
      if (nextSource != null) {
        for (var nextKey in nextSource) {
          if (hasOwnProp(nextSource, nextKey)) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    });
    return to;
  };
}

// 'string'.includes and ['array'].includes
// ----------------------------------------------------------------------------
function _protoIncludes(search, start) {
  if (search instanceof RegExp) {
    throw TypeError('first argument must not be a RegExp');
  }
  if (start === undefined) {
    start = 0;
  }
  return this.indexOf(search, start) !== -1;
}
if (!stringProto.includes) {
  stringProto.includes = _protoIncludes;
}
if (!arrayProto.includes) {
  arrayProto.includes = _protoIncludes;
}

// 'string'.startsWith/endsWith
// ----------------------------------------------------------------------------
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (search, pos) {
    pos = typeof pos !== 'number' || pos < 0 ? 0 : Math.floor(pos);
    return this.slice(pos, search.length) === search;
  };
}
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (search, pos) {
    var len = this.length;
    pos = typeof pos !== 'number' || pos > len ? len : Math.floor(pos);
    return this.substring(pos - search.length, pos) === search;
  };
}

// ['array'].find and ['array'].findIndex
// ----------------------------------------------------------------------------
function _makeFind(returnIndex) {
  return function findPolyfill(callback) {
    if (this == null) {
      throw new TypeError('"this" is null or undefined');
    }
    if (typeof callback !== 'function') {
      throw new TypeError('callback must be a function');
    }

    var obj = Object(this);
    var len = obj.length >>> 0;
    var thisArg = arguments[1];
    var i = 0;
    while (i < len) {
      var val = obj[i];
      if (callback.call(thisArg, val, i, obj)) {
        return returnIndex ? i : val;
      }
      i++;
    }

    return undefined;
  };
}
if (!arrayProto.findIndex) {
  arrayProto.findIndex = _makeFind(true);
}
if (!arrayProto.find) {
  arrayProto.find = _makeFind(false);
}

// NodeList.forEach
// ----------------------------------------------------------------------------
if (!nodeListProto.forEach) {
  nodeListProto.forEach = arrayProto.forEach;
}

// Element.remove
// ----------------------------------------------------------------------------
if (!elementProto.remove) {
  elementProto.remove = function () {
    this.parentNode.removeChild(this);
  };
}

// Element/DocumentFragment.prepend/append and Element.before/after
// ----------------------------------------------------------------------------
function _getNodesFragment(nodes) {
  var frag = document.createDocumentFragment();
  nodes.forEach(function (arg) {
    frag.appendChild(
      arg instanceof Node ? arg : document.createTextNode(String(arg))
    );
  });
  return frag;
}
[elementProto, documentFragmentProto].forEach(function (proto) {
  if (!proto.prepend) {
    proto.prepend = function () {
      this.insertBefore(_getNodesFragment(toArray(arguments)), this.firstChild);
    };
  }
  if (!proto.append) {
    proto.append = function () {
      this.appendChild(_getNodesFragment(toArray(arguments)));
    };
  }
});
if (!elementProto.before) {
  elementProto.before = function () {
    this.parentNode.insertBefore(_getNodesFragment(toArray(arguments)), this);
  };
}
if (!elementProto.after) {
  elementProto.after = function () {
    this.parentNode.insertBefore(
      _getNodesFragment(toArray(arguments)),
      this.nextSibling
    );
  };
}

// Element.matches specifically for IE
// ----------------------------------------------------------------------------
if (!elementProto.matches) {
  elementProto.matches = elementProto.msMatchesSelector;
}

// Element.closest
// ----------------------------------------------------------------------------
if (!elementProto.closest) {
  elementProto.closest = function (selector) {
    var elem = this;
    var ancestor = this;
    if (!document.documentElement.contains(elem)) {
      return null;
    }
    do {
      if (ancestor.matches(selector)) {
        return ancestor;
      }
      ancestor = ancestor.parentElement;
    } while (ancestor !== null);
    return null;
  };
}

// MediaQueryList.addEventListener/removeEventListener
// The older addListener/removeListener are deprecated but the replacement
// isn't polyfilled by matchmedia-polyfill.
// ----------------------------------------------------------------------------
if (
  !mediaQueryListProto.addEventListener &&
  mediaQueryListProto.addListener &&
  mediaQueryListProto.removeListener
) {
  mediaQueryListProto.addEventListener = function (eventName, callback) {
    if (eventName === 'change') {
      this.addListener(callback);
    }
  };
  mediaQueryListProto.removeEventListener = function (eventName, callback) {
    if (eventName === 'change') {
      this.removeListener(callback);
    }
  };
}
