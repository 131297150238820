import { arraysHaveSameValues, classNames, el } from '../utils';
import BaseComponent from './base-component';

// E.g. 'consent_example_com'
const COOKIE_NAME = `consent_${window.location.hostname.replace(/[^\w]/, '_')}`;
const COOKIE_ACCEPT_VALUE = '1';
const COOKIE_REJECT_VALUE = '0';
const BASE_CLASS = 'consent';
const KEY_VAL_SEP = ':';
const GROUP_SEP = '|';

/**
 * A group that items may be connected to.
 */
class Group {
  /** @type {string} */
  id;

  /** @type {string} */
  label;

  /** @type {boolean} */
  isRequired;

  /**
   * @param {object} data
   */
  constructor(data) {
    Object.assign(this, data);
    this.isRequired = Boolean(data.isRequired);
  }
}

/**
 * A piece of HTML to insert when consent has been given.
 */
class Item {
  /** @type {string} */
  tag;

  /** @type {string} */
  text;

  /** @type {?string} */
  group;

  /** @type {?object} */
  attrs;

  /**
   * @param {object} data
   */
  constructor(data) {
    Object.assign(this, data);
  }
}

/**
 * Privacy consent dialog, often called a cookie banner.
 *
 * Inserts the dialog when relevant, handles saving the selected options and
 * inserts scripts and other elements that consent has been acquired for.
 * Can also insert buttons to trigger the this.dialog.
 *
 * If using groups, the required one(s) are only for show and not included in
 * the saved value.
 */
export default class PrivacyConsent extends BaseComponent {
  /** @type {?HTMLElement} */
  dialog;

  /** @type {Array.<HTMLElement>} */
  dialogFocusableChildren = [];

  /**
   * Element that triggered the dialog.
   *
   * @type {?HTMLElement}
   */
  pressedOpener;

  /** @type {object} */
  options;

  /** @type {Array.<Item>} */
  consentItems;

  /** @type {Array.<Group>} */
  groups;

  /** @type {boolean} */
  hasGroups = false;

  init() {
    this.options = window.consentOptions;
    if (!this.options) {
      throw new Error('Missing consent options');
    }

    this.consentItems = Array.isArray(window.consentItems)
      ? window.consentItems.map((data) => new Item(data))
      : [];
    this.groups = Array.isArray(this.options.groups)
      ? this.options.groups.map((g) => new Group(g))
      : [];
    this.hasGroups = Boolean(this.groups.length);

    const status = this.getConsentStatus();
    const statusType = typeof status;
    // Ask for consent if it's missing or if the saved value doesn't match
    // what's currently asked. Could be a change from no groups to groups and
    // vice versa, or that the available groups have changed.
    if (
      status === undefined ||
      (this.hasGroups && statusType === 'boolean') ||
      (!this.hasGroups && statusType === 'object') ||
      (this.hasGroups &&
        statusType === 'object' &&
        !arraysHaveSameValues(
          Object.keys(status),
          this.groups.filter(this.isOptional).map(this.getId)
        ))
    ) {
      this.addDialog(false);
    } else if (status === true || statusType === 'object') {
      this.insertConsentItems();
    }

    this.addDialogOpeners();
  }

  /**
   * Get the consent cookie value.
   *
   * @returns {string}
   */
  getCookie() {
    // Split on a value like `; one=1; two=2`. No match means a one item array
    // with the entire cookie string.
    const parts = `; ${document.cookie}`.split(`; ${COOKIE_NAME}=`);
    return parts.length > 1 ? parts[1].split(';')[0] : null;
  }

  /**
   * Set the consent cookie value.
   *
   * @param {string} value
   */
  setCookie(value) {
    document.cookie = `${COOKIE_NAME}=${value}; max-age=31536000; path=/; samesite=strict`;
  }

  /**
   * Get saved consent status, if any
   *
   * @returns {boolean|object|undefined} - True/false if not using groups and
   *   an accept/reject has been saved. An object of groupName: true/false for
   *   each group if using groups, and undefined if no decision has been made.
   */
  getConsentStatus() {
    const val = this.getCookie();
    if (!val) {
      return undefined;
    }
    if (val.indexOf(KEY_VAL_SEP) !== -1) {
      return val.split(GROUP_SEP).reduce((groups, rawGroup) => {
        const groupData = rawGroup.split(KEY_VAL_SEP);
        groups[groupData[0]] = groupData[1] === COOKIE_ACCEPT_VALUE;
        return groups;
      }, {});
    }
    return val === COOKIE_ACCEPT_VALUE;
  }

  /**
   * @param {string} groupId
   * @returns {boolean}
   */
  isRequiredGroup(groupId) {
    if (!this.hasGroups) {
      return false;
    }
    return (
      this.groups.filter(this.isRequired).map(this.getId).indexOf(groupId) !==
      -1
    );
  }

  /**
   * Add all scripts, and other things that require consent, to the DOM.
   */
  insertConsentItems() {
    const status = this.getConsentStatus();
    if (!status) {
      return;
    }

    // A somewhat unusual loop is required since the array is modified during
    // iteration by removing data for inserted items. This way this function
    // can be called any number of times without risking duplicate scripts.
    let i = 0;
    while (this.consentItems.length > i) {
      const itemData = this.consentItems[i];

      // Skip rejected group and jump forward one index for the next iteration.
      if (
        this.hasGroups &&
        itemData.group &&
        typeof status === 'object' &&
        !status[itemData.group]
      ) {
        i += 1;
        continue;
      }

      const node = el(itemData.tag, itemData.attrs, itemData.text);
      const appendTarget =
        ['meta', 'link', 'script'].indexOf(itemData.tag) !== -1
          ? document.head
          : document.body;
      appendTarget.appendChild(node);

      this.consentItems.splice(i, 1);
    }
  }

  removeDialog() {
    this.dialog.removeEventListener('click', this.handleDialogClick);
    this.dialog.removeEventListener('keydown', this.handleDialogKeydown);

    this.dialog.remove();
    this.dialog = null;

    if (this.pressedOpener) {
      this.pressedOpener.focus();
      this.pressedOpener = null;
    }
  }

  /**
   * Primary action button handler, always an 'accept all'.
   */
  handlePrimaryClick = () => {
    if (this.hasGroups) {
      this.setCookie(
        this.groups
          .filter(this.isOptional)
          .map((g) => g.id + KEY_VAL_SEP + COOKIE_ACCEPT_VALUE)
          .join(GROUP_SEP)
      );
    } else {
      this.setCookie(COOKIE_ACCEPT_VALUE);
    }
    this.insertConsentItems();
    this.removeDialog();
  };

  /**
   * Primary action button handler, either a 'reject all' or a 'save settings'
   * depending on groups.
   */
  handleSecondaryClick = () => {
    if (this.hasGroups) {
      this.setCookie(
        this.selectAll('input[type="checkbox"]', this.dialog)
          .filter((input) => !this.isRequiredGroup(input.name))
          .map(
            (input) =>
              input.name +
              KEY_VAL_SEP +
              (input.checked ? COOKIE_ACCEPT_VALUE : COOKIE_REJECT_VALUE)
          )
          .join(GROUP_SEP)
      );
    } else {
      this.setCookie(COOKIE_REJECT_VALUE);
    }
    this.insertConsentItems();
    this.removeDialog();
  };

  /**
   * Close the dialog when clicking the backdrop.
   *
   * @param {MouseEvent} e
   */
  handleDialogClick = (e) => {
    if (e.target.classList.contains(`${BASE_CLASS}-backdrop`)) {
      // Skip focus restoration since mouse users can scroll - the pressed
      // opener could have been scrolled out of view and a focus will scroll
      // back to that position which could be jarring.
      this.pressedOpener = null;
      this.removeDialog();
    }
  };

  /**
   * Close the dialog on escape and trap keyboard focus.
   *
   * @param {KeyboardEvent} e
   */
  handleDialogKeydown = (e) => {
    if (e.key === 'Esc' || e.key === 'Escape') {
      this.removeDialog();
    } else if (e.key === 'Tab' && this.dialogFocusableChildren.length) {
      const focused = document.activeElement;
      const firstFocusable = this.dialogFocusableChildren[0];
      const lastFocusable =
        this.dialogFocusableChildren[this.dialogFocusableChildren.length - 1];
      if (focused === firstFocusable && e.shiftKey) {
        lastFocusable.focus();
        e.preventDefault();
      } else if (
        (focused === lastFocusable && !e.shiftKey) ||
        !this.dialog.contains(focused)
      ) {
        firstFocusable.focus();
        e.preventDefault();
      }
    }
  };

  /**
   * Add the consent dialog, only making it a 'real' modal if it's triggered
   * manually via an opener button.
   *
   * @param {boolean} isTriggeredManually
   */
  addDialog(isTriggeredManually) {
    const {
      actionsAlign,
      theme,
      secondaryActionClass,
      secondaryActionText,
      primaryActionClass,
      primaryActionText,
      policyPageUrl,
      policyPageTitle,
      position,
      groupsLegend,
      groups,
      title,
      text,
      policyPagePrefix,
    } = this.options;

    const btnBaseClass = `${BASE_CLASS}__action`;
    const secondaryBtn = el(
      'button',
      {
        type: 'button',
        class: classNames([
          btnBaseClass,
          actionsAlign === 'full' && `${btnBaseClass}--full`,
          `${btnBaseClass}--secondary`,
          `${btnBaseClass}--secondary--${theme}`,
          secondaryActionClass,
        ]),
      },
      secondaryActionText
    );
    const primaryBtn = el(
      'button',
      {
        type: 'button',
        class: classNames([
          btnBaseClass,
          actionsAlign === 'full' && `${btnBaseClass}--full`,
          `${btnBaseClass}--primary`,
          `${btnBaseClass}--primary--${theme}`,
          primaryActionClass,
        ]),
      },
      primaryActionText
    );
    const policyPageLink =
      policyPageUrl && policyPageTitle
        ? el('a', { href: policyPageUrl }, policyPageTitle)
        : null;

    const posClasses = position
      .split('-')
      .map((c) => `${BASE_CLASS}--pos-${c}`);

    let groupFields;
    if (this.hasGroups) {
      const status = this.getConsentStatus();
      groupFields = el(
        'fieldset',
        { class: `${BASE_CLASS}__groups` },
        [el('legend', null, groupsLegend)].concat(
          groups.map((group) => {
            const inputAttr = { type: 'checkbox', name: group.id };
            if (group.isRequired) {
              inputAttr.checked = '';
              inputAttr.disabled = '';
              inputAttr.required = '';
            } else if (typeof status === 'object' && status[group.id]) {
              inputAttr.checked = '';
            }
            return el('label', { class: `${BASE_CLASS}__group` }, [
              el('input', inputAttr),
              el('span', null, group.label),
            ]);
          })
        )
      );
    }

    const titleId = 'consent-title';
    const dialogAttr = {
      'role': 'dialog',
      'aria-labelledby': titleId,
      'class': classNames(
        [BASE_CLASS, `${BASE_CLASS}--theme-${theme}`].concat(posClasses)
      ),
    };
    if (isTriggeredManually) {
      dialogAttr.class += ` ${BASE_CLASS}--modal`;
      dialogAttr['aria-modal'] = 'true';
    }
    this.dialog = el(
      'div',
      {
        class: classNames([
          `${BASE_CLASS}-backdrop`,
          isTriggeredManually && `${BASE_CLASS}-backdrop--modal`,
        ]),
      },
      el('div', dialogAttr, [
        el('div', { class: `${BASE_CLASS}__content` }, [
          el('h2', { class: `${BASE_CLASS}__title`, id: titleId }, title),
          el('p', { class: `${BASE_CLASS}__text` }, [
            `${text} `,
            policyPageLink && policyPagePrefix ? `${policyPagePrefix} ` : '',
            policyPageLink,
          ]),
        ]),
        groupFields,
        el(
          'div',
          {
            class: classNames([
              `${BASE_CLASS}__actions`,
              `${BASE_CLASS}__actions--${actionsAlign}`,
            ]),
          },
          [secondaryBtn, primaryBtn]
        ),
      ])
    );

    document.body.insertBefore(this.dialog, document.body.firstElementChild);

    secondaryBtn.addEventListener('click', this.handleSecondaryClick, false);
    primaryBtn.addEventListener('click', this.handlePrimaryClick, false);

    if (isTriggeredManually) {
      this.dialogFocusableChildren = this.selectAll(
        'a, button:not(:disabled), input:not(:disabled)',
        this.dialog
      );
      this.dialog.addEventListener('click', this.handleDialogClick);
      this.dialog.addEventListener('keydown', this.handleDialogKeydown);
      requestAnimationFrame(() => {
        secondaryBtn.focus();
      });
    }
  }

  /**
   * @param {MouseEvent} e
   */
  handleOpenerClick = (e) => {
    if (!this.dialog) {
      this.pressedOpener = e.currentTarget;
      this.addDialog(true);
    }
  };

  addDialogOpeners() {
    const baseOpener = el(
      'button',
      {
        type: 'button',
        class: classNames([`${BASE_CLASS}-opener`, this.options.openerClass]),
      },
      this.options.openerText
    );

    const targets = this.options.openerAppendSelector
      ? this.selectAll(this.options.openerAppendSelector)
      : [];
    targets.forEach((target) => {
      const opener = baseOpener.cloneNode(true);
      opener.addEventListener('click', this.handleOpenerClick, false);
      target.appendChild(opener);
    });
  }

  // Some filter/map shorthands

  /**
   * @param {Group} group
   * @returns {boolean}
   */
  isRequired(group) {
    return group.isRequired;
  }

  /**
   * @param {Group} group
   * @returns {boolean}
   */
  isOptional(group) {
    return !group.isRequired;
  }

  /**
   * @param {Group} group
   * @returns {string}
   */
  getId(group) {
    return group.id;
  }
}
