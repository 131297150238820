/**
 * Detect support for features and add class names to the HTML element.
 *
 * A less comprehensive and therefore much smaller variant of Modernizr.
 */

import { camelCase, upperFirst } from './utils';

// https://github.com/Modernizr/Modernizr/blob/master/src/omPrefixes.js
// https://github.com/Modernizr/Modernizr/issues/issue/21
const VENDOR_PREFIXES = ['Moz', 'O', 'ms', 'Webkit'];

const STYLE = document.createElement('div').style;

/**
 * Test if a CSS property is supported.
 *
 * https://github.com/Modernizr/Modernizr/blob/master/src/testProps.js
 *
 * @param {string} property - CSS property name in kebab-case.
 * @param {*} [value] - Test a specific value.
 * @returns {boolean}
 */
function propertyIsSupported(property, value = null) {
  const camelProp = camelCase(property);
  const prefixedProps = [camelProp].concat(
    VENDOR_PREFIXES.map((prefix) => `${prefix}${upperFirst(camelProp)}`)
  );
  return prefixedProps.some((prop) => {
    const before = STYLE[prop];

    // If the property is supported it will return an empty string,
    // if unsupported it will return undefined.
    if (STYLE[prop] !== undefined) {
      // If value to test has been passed in, do a set-and-check test.
      // Assume support if it has changed.
      if (value !== null) {
        STYLE[prop] = value;
        return STYLE[prop] !== before;
      }
      return true;
    }

    return false;
  });
}

/**
 * Set a class on the HTML element depending on CSS property support.
 *
 * @param {string} property - CSS property name in kebab-case.
 * @param {*} [value] - Test a specific value.
 * @returns {boolean}
 */
function testCSS(property, value = null) {
  const classPrefix = propertyIsSupported(property, value) ? 'has' : 'no';
  document.documentElement.classList.add(`${classPrefix}-${property}`);
}

/**
 * Run all feature tests.
 */
export default function featureTests() {
  testCSS('object-fit', 'cover');
  testCSS('mix-blend-mode', 'difference');
}
