import { getData, kebabCase } from '../utils';
import BaseComponent from './base-component';

const STAFF_GROUP_NODE_NAME = 'staff-group';

class FilterGroup {
  /**
   * @param {HTMLInputElement} radio
   * @param {HTMLElement} staffContainer
   * @param {string} id
   */
  constructor(radio, staffContainer = null, id = '') {
    this.radio = radio;
    this.staffContainer = staffContainer;
    this.id = id;
  }
}

/**
 * Filtering radio buttons for staff groups.
 */
export default class StaffFilter extends BaseComponent {
  /** @type {Array.<FilterGroup>} */
  filterGroups = [];

  init() {
    this.setGroupData();

    // Ensure the 'all' item (empty ID and value) is checked by default.
    this.filterGroups.find((group) => !group.id).radio.checked = true;

    this.filterGroups.forEach((group) => {
      group.radio.addEventListener('change', this.handleFilterRadioChange);
    });
  }

  /**
   * Create filter radios for all relevant staff groups and save group data.
   */
  setGroupData() {
    const staffGroups = [];
    let sibling = this.root;
    // Specifically loop siblings rather than selecting from the parent, to
    // force a logical placement of the filter. Otherwise it can be positioned
    // in the middle of a group of groups and filter those that come before.
    // This also opens the possibility of multiple filters on the same page,
    // even if that's probably not the best idea.
    // eslint-disable-next-line no-cond-assign
    while ((sibling = sibling.nextElementSibling)) {
      if (getData(sibling, 'component') === 'staff-filter') {
        break;
      }
      if (getData(sibling, 'node-name') === STAFF_GROUP_NODE_NAME) {
        staffGroups.push(sibling);
      } else {
        staffGroups.push(...this.selectAll(STAFF_GROUP_NODE_NAME, sibling));
      }
    }

    // The template has the initial 'all' item.
    const filterAllItem = this.selectSingle('filter-item');
    this.filterGroups = [
      new FilterGroup(this.selectSingle('input', filterAllItem)),
    ];

    staffGroups.forEach((staffContainer) => {
      const title = this.selectSingle(
        'h2,h3,h4',
        staffContainer
      ).textContent.trim();
      const id = kebabCase(title);

      const newFilterItem = filterAllItem.cloneNode(true);
      const newItemRadio = this.selectSingle('input', newFilterItem);
      const newItemLabel = this.selectSingle('label', newFilterItem);
      newItemRadio.removeAttribute('checked');
      newItemRadio.value = id;
      newItemLabel.textContent = title;

      filterAllItem.parentNode.appendChild(newFilterItem);

      this.filterGroups.push(new FilterGroup(newItemRadio, staffContainer, id));
    });
  }

  /**
   * @param {Event} e - Input change event.
   */
  handleFilterRadioChange = (e) => {
    const selectedRadio = e.currentTarget;
    const isAll = !selectedRadio.value;
    this.filterGroups.forEach((group) => {
      // The 'all' item doesn't have a staff container.
      if (!group.id) {
        return;
      }
      if (isAll || group.radio === selectedRadio) {
        group.staffContainer.removeAttribute('hidden');
      } else {
        group.staffContainer.setAttribute('hidden', '');
      }
    });
  };
}
