import './polyfills';

import Parvus from 'parvus';
import parvusEn from 'parvus/src/l10n/en';

import { IS_IE11, onReady } from './utils';

import DatasetArchive from './components/dataset-archive';
import DatasetForm from './components/dataset-form';
import Form from './components/form';
import Menu from './components/menu';
import PrivacyConsent from './components/privacy-consent';
import StaffFilter from './components/staff-filter';
import Toggle from './components/toggle';

import componentManager from './component-manager';
import featureTests from './feature-tests';
import fixSkiplinks from './fix-skiplinks';
import focusClass from './focus-class';

// Feature tests should run as early as possible, don't wait for DOM ready.
featureTests();

// The manager handles ready/load, run right away.
componentManager
  .register({
    'dataset-archive': DatasetArchive,
    'dataset-form': DatasetForm,
    'form': Form,
    'menu': Menu,
    'privacy-consent': PrivacyConsent,
    'staff-filter': StaffFilter,
    'toggle': Toggle,
  })
  .init();

onReady(() => {
  fixSkiplinks();
  focusClass();

  // Init lightbox if not IE11, it's broken there.
  if (!IS_IE11) {
    // eslint-disable-next-line no-unused-vars
    const parvus = new Parvus({
      selector: '.images-block__item-image > a',
      gallerySelector: '.images-block',
      swipeClose: false,
      // Override some labels, especially lightboxLabel that has an extremely
      // verbose default.
      l10n: {
        ...parvusEn,
        lightboxLabel: 'Enlarged images dialog. Press Escape to close.',
        closeButtonLabel: 'Close images dialog',
      },
    });
  }
});
