const HIDE_CLASS_NAME = 'hide-focus';
const SHOW_CLASS_NAME = 'show-focus';
// Keys that are commonly used for keyboard navigation. Match event.key values.
const CONTROL_KEYS = [
  ' ',
  'Tab',
  'Enter',
  'Escape',
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
];

/**
 * Manage a focus visibility class name.
 *
 * Focus outlines can be annoying or feel out of place for mouse users, so this
 * handles a class name on the html element that indicates when the outlines
 * can be hidden or definitely needs to show. The 'hiding class' is added when
 * mouse interaction is registered and the opposite when typical keyboard
 * control keys are pressed (tab, enter etc.).
 *
 * No class is set by default, but the initial premise should ALWAYS be that
 * outlines are needed. Showing outlines for one that doesn't need them is way
 * better than not showing them for one who relies on them.
 *
 * :focus-visible is available for native support but browser heuristics tend
 * to make that work a bit differently than this.
 */
export default function focusClass() {
  const html = document.documentElement;
  let isHidden = null;
  let hasTouchAndMouse = true;

  function hideFocus() {
    html.classList.add(HIDE_CLASS_NAME);
    html.classList.remove(SHOW_CLASS_NAME);
    isHidden = true;
  }

  function showFocus() {
    html.classList.add(SHOW_CLASS_NAME);
    html.classList.remove(HIDE_CLASS_NAME);
    isHidden = false;
  }

  /**
   * Keydown event callback, show focus if relevant.
   *
   * @param {KeyboardEvent} e
   */
  function handleKeydown(e) {
    if (isHidden === false) {
      return;
    }

    if (CONTROL_KEYS.includes(e.key)) {
      showFocus();
    }
  }

  /**
   * Pointer start callback (mouse, pointer, touch...), hide focus.
   *
   * @param {PointerEvent} e
   */
  function handlePointer(e) {
    if (isHidden === true) {
      return;
    }

    // Remove mouse event if a touch is registered. This avoids duplicate
    // firing of the handler, since touch devices also fire mouse events for
    // compatibility.
    if (hasTouchAndMouse && e.type === 'touchstart') {
      html.removeEventListener('mousedown', handlePointer);
      hasTouchAndMouse = false;
    }

    hideFocus();
  }

  // Trying to use as few as possible while still including touch. Some desktop
  // browsers expose touch events even if they're unusable in practice, so both
  // touch and mouse must be bound simultaneously.
  //
  // Some screen readers and assistive technology for touchscreens fire mouse
  // events when activating elements, so it's not perfect.
  // In the screen reader case, hiding the outline hopefully shouldn't matter
  // too much, unless a sighted user uses it to read but not necessarily to
  // navigate. The outline will be visible again on the next Tab press in that
  // case.
  // The touchscreen case should, again 'hopefully', not require an outline if
  // actual tapping occurs.
  //
  // http://patrickhlauke.github.io/touch/tests/results/
  if (window.PointerEvent) {
    html.addEventListener('pointerdown', handlePointer);
  } else {
    html.addEventListener('touchstart', handlePointer);
    html.addEventListener('mousedown', handlePointer);
  }
  html.addEventListener('keydown', handleKeydown);
}
