import componentManager from '../component-manager';
import { getData } from '../utils';
import BaseComponent from './base-component';
// Import Field for typing.
// eslint-disable-next-line no-unused-vars
import Form, { Field } from './form';

const DOWNLOAD_AUTO_START_DELAY = 1000;

/**
 * Dataset download form.
 */
export default class DatasetForm extends BaseComponent {
  /** @type {HTMLFormElement} */
  form;

  /** @type {Form} */
  formComponent;

  /** @type {HTMLElement} */
  successText;

  /** @type {HTMLAnchorElement} */
  successTextDownloadLink;

  /** @type {HTMLElement} */
  successTextReturn;

  /** @type {Array.<string>} */
  personalInfoFieldNames = [];

  init() {
    this.form = this.selectSingle('form');

    // A bit hacky, wait for the base form component to be initialized
    // since its state is in this component.
    setTimeout(() => {
      // The generic form component has data and helpers for fields.
      this.formComponent = componentManager.getInstanceForElement(
        this.form,
        Form
      );
      this.successText = this.selectSingle('success-text');
      this.successTextDownloadLink = this.selectSingle(
        'success-text-download-link'
      );
      this.successTextReturn = this.selectSingle('success-text-return');

      // Replace return link with button.
      const successReturnText =
        this.successTextReturn.querySelector('a').textContent;
      this.successTextReturn.innerHTML = `<button type="button">${successReturnText}</button>`;

      this.personalInfoFieldNames = getData(
        this.root,
        'personal-info-field-names'
      ).split(',');

      this.bindEvents();
      this.checkFieldRequirements();
    });
  }

  /**
   * @param {Field} field
   * @param {boolean} isRequired
   */
  _setRequired(field, isRequired) {
    field.elements.forEach((el) => {
      el.required = isRequired;
    });
    if (isRequired) {
      field.requiredStar.removeAttribute('hidden');
    } else {
      field.requiredStar.setAttribute('hidden', '');
    }
  }

  /**
   * Make the 'accept terms' checkbox required if any personal information
   * fields are filled, and vice versa.
   */
  setAcceptTermsRequired() {
    const acceptTermsField = this.formComponent.getField('accept_terms');
    const hasData = this.personalInfoFieldNames.some((fieldName) => {
      const inputs = this.formComponent.getField(fieldName).elements;
      return inputs.some((input) =>
        ['checkbox', 'radio'].includes(input.type)
          ? input.value && input.checked
          : input.value
      );
    });
    this._setRequired(acceptTermsField, hasData);
  }

  /**
   * Make the email field required if the newsletter checkbox is checked, and
   * vice versa.
   */
  setNewsletterRequired() {
    const newsletterField = this.formComponent.getField('newsletter');
    if (!newsletterField) {
      return;
    }
    const emailField = this.formComponent.getField('email');
    this._setRequired(emailField, newsletterField.elements[0].checked);
  }

  /**
   * Ensure the "don't want to say" option isn't checked together with the
   * other gender options.
   *
   * @param {HTMLInputElement} changedInput - The input that was just changed.
   */
  checkGenderValues(changedInput) {
    if (!changedInput.checked) {
      return;
    }
    const genderField = this.formComponent.getField('gender');
    if (changedInput.value) {
      genderField.elements.find((input) => !input.value).checked = false;
    } else {
      genderField.elements.forEach((input) => {
        if (input.value) {
          input.checked = false;
        }
      });
    }
  }

  checkFieldRequirements() {
    this.setAcceptTermsRequired();
    this.setNewsletterRequired();
  }

  /**
   * @param {InputEvent|Event} e - `input` or `change` event, depending on the
   *   input type.
   */
  handleFieldChange = (e) => {
    const field = this.formComponent.getField(e.target.name);

    if (field.baseName === 'gender') {
      this.checkGenderValues(e.target);
    }
    if (this.personalInfoFieldNames.includes(field.baseName)) {
      this.setAcceptTermsRequired();
    }
    if (field.baseName === 'newsletter') {
      this.setNewsletterRequired();
    }
  };

  /**
   * Show 'thank you' box and trigger the download on form success.
   *
   * @param {object} formResponse
   */
  handleFormSuccess = (formResponse) => {
    this.successTextDownloadLink.href = formResponse.dataset_url;
    this.root.scrollIntoView(true);
    this.form.setAttribute('hidden', '');
    this.successText.removeAttribute('hidden');
    // Let the user react a little bit before starting.
    this.downloadTimer = setTimeout(() => {
      this.successTextDownloadLink.click();
    }, DOWNLOAD_AUTO_START_DELAY);
  };

  /**
   * Cancel automatic download timer if the user starts if earlier.
   */
  handleDownloadLinkClick = () => {
    if (this.downloadTimer) {
      clearTimeout(this.downloadTimer);
      this.downloadTimer = null;
    }
  };

  /**
   * Restore form.
   */
  handleSuccessReturnClick = () => {
    this.checkFieldRequirements();
    this.form.removeAttribute('hidden');
    this.successText.setAttribute('hidden', '');
    this.successTextDownloadLink.href = '';
  };

  bindEvents() {
    this.formComponent.addSuccessCallback(this.handleFormSuccess);
    this.formComponent.fields.forEach((field) => {
      field.elements.forEach((el) => {
        const eventName = ['checkbox', 'radio'].includes(el.type)
          ? 'change'
          : 'input';
        el.addEventListener(eventName, this.handleFieldChange);
      });
    });
    this.successTextDownloadLink.addEventListener(
      'click',
      this.handleDownloadLinkClick
    );
    this.successTextReturn
      .querySelector('button')
      .addEventListener('click', this.handleSuccessReturnClick);
  }
}
