// Cached regexes
const REGEX = {
  onlyNumber: /^-?\d+$/,
  // Also make sure the number of periods are exactly one if testing for float
  onlyNumberWithPeriods: /^-?[\d.]+$/,
  validNodeName: /^[\w\d-]+$/,
  leadingData: /^data-?/i,
};

const NODE_NAME_ATTRIBUTE = 'data-node-name';

/** No-break space character. */
export const NBSP = '\u00A0';

/** If the current browser is Internet Explorer 11. */
export const IS_IE11 = Boolean(window.msCrypto);

/**
 * Run a function when the document is ready. Runs right away if already ready.
 *
 * @param {Function} cb - Callback to run.
 */
export function onReady(cb) {
  if (
    document.readyState === 'interactive' ||
    document.readyState === 'complete'
  ) {
    cb();
  } else {
    document.addEventListener('DOMContentLoaded', cb, false);
  }
}

/**
 * Run a function when all resources are loaded, or right away if already
 * loaded.
 *
 * @param {Function} cb - Callback to run.
 */
export function onLoad(cb) {
  if (document.readyState === 'complete') {
    cb();
  } else {
    window.addEventListener('load', cb, false);
  }
}

/**
 * Check if the specified property is a direct property of an object.
 *
 * @param {object} obj
 * @param {string} prop
 * @returns {boolean}
 */
export function hasOwnProp(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * Remove a prefix from a string.
 *
 * @param {string} str
 * @param {string} prefix
 * @returns {string}
 */
export function removePrefix(str, prefix) {
  return str.startsWith(prefix) ? str.slice(prefix.length) : str;
}

/**
 * Ensure a value is an array.
 *
 * `asArray(3)` and `asArray([3])` will both result in [3].
 *
 * @param {*} val
 * @returns {Array}
 */
export function asArray(val) {
  return Array.isArray(val) ? val : [val];
}

/**
 * Convert a value to an array if possible.
 *
 * @param {*} val
 * @returns {Array}
 */
export function toArray(val) {
  if (!val) {
    return [];
  }
  if (Array.isArray(val)) {
    return val;
  }
  if (typeof val.length !== 'number') {
    throw new Error('Value type not handled');
  }
  const arr = [];
  for (let i = 0; i < val.length; i += 1) {
    arr.push(val[i]);
  }
  return arr;
}

/**
 * Check if two arrays contain the same values, in any order.
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {boolean}
 */
export function arraysHaveSameValues(a, b) {
  if (a.length !== b.length) {
    return false;
  }

  // Clone with slice to avoid mutating the original.
  const sortedA = a.slice().sort();
  const sortedB = b.slice().sort();

  return sortedA.every((val, i) => sortedB[i] === val);
}

/**
 * Check if a value is empty. Includes falsy values as well as empty arrays
 * and objects.
 *
 * @param {*} val
 * @returns {boolean}
 */
export function isEmpty(val) {
  if (!val) {
    return true;
  }
  if (typeof val.length === 'number') {
    return val.length === 0;
  }
  if (typeof val === 'object') {
    return Object.keys(val).length === 0;
  }
  return false;
}

/**
 * Check if a value is a valid `data-node-name`.
 *
 * @param {string} value
 * @returns {boolean}
 */
function _isValidNodeName(value) {
  // Values are cast to strings so default any falsy values.
  return REGEX.validNodeName.test(value || '');
}

/**
 * Get a `data-node-name` selector.
 *
 * @param {string} selector - Raw selector.
 * @returns {string}
 */
function _getNodeNameSelector(selector) {
  return `[${NODE_NAME_ATTRIBUTE}~="${selector}"]`;
}

/**
 * Select one or multiple nodes.
 *
 * Using a data attribute value is a way of separating the JS logic from
 * styling hooks - a class name can then be changed without any scripts
 * breaking.
 *
 * @param {HTMLElement} parent - Node to select from.
 * @param {string} selector - Selector, CSS or a data-node-name value.
 * @param {boolean} isMulti - If selecting multiple nodes rather than a single.
 * @returns {HTMLElement|Array.<HTMLElement>|null}
 */
function _select(parent, selector, isMulti) {
  const select = (s) =>
    isMulti ? parent.querySelectorAll(s) : parent.querySelector(s);
  // Selector is a valid data-node-name, assume that's the intended use
  // rather than an HTML element for example.
  if (_isValidNodeName(selector)) {
    const result = select(_getNodeNameSelector(selector));
    const hasResult = isMulti ? result.length : result;
    // Use default selection below as a fallback.
    if (hasResult) {
      return isMulti ? toArray(result) : result;
    }
  }
  // Default to using the selector as is.
  return isMulti ? toArray(select(selector)) : select(selector);
}

/**
 * Get all nodes matching the selector inside a parent.
 *
 * @param {string} selector - CSS selector or data-node-name value.
 * @param {Element} [parent] - Parent node, defaults to the document.
 * @returns {Array.<HTMLElement>} An array of found elements, can be empty.
 */
export function selectAll(selector, parent = document) {
  return _select(parent, selector, true);
}

/**
 * Get all nodes matching the selector inside a parent.
 *
 * @param {string} selector - CSS selector or data-node-name value.
 * @param {Element} [parent] - Parent node, defaults to the document.
 * @returns {?HTMLElement} Found element or null.
 */
export function selectSingle(selector, parent = document) {
  return _select(parent, selector, false);
}

/**
 * Create an element.
 *
 * @param {string} tagName
 * @param {object} [attrs] - Attribute name-value pairs.
 * @param {string|HTMLElement|Array.<string|HTMLElement>} [children] - One or
 *   more children to add to the created element.
 * @returns {HTMLElement}
 */
export function el(tagName, attrs, children) {
  const elem = document.createElement(tagName);
  if (attrs) {
    Object.entries(attrs).forEach(([attr, val]) => {
      elem.setAttribute(attr, val);
    });
  }
  if (children) {
    []
      .concat(children)
      .filter(Boolean)
      .map((c) => (typeof c === 'string' ? document.createTextNode(c) : c))
      .forEach((c) => {
        elem.appendChild(c);
      });
  }
  return elem;
}

/**
 * Join class names, excluding falsy values.
 *
 * @param {string|Array.<string>|object} names - Strings and arrays of strings
 *   to add as is, or objects where the key is the class name and the value's
 *   truthiness determines if it should be added.
 * @returns {string}
 * @example
 *
 * classNames({ yes: true, no: false }, 'str', null)
 * // => 'yes str'
 */
export function classNames(...args) {
  return args
    .filter(Boolean)
    .reduce((classes, arg) => {
      const argType = typeof arg;

      if (argType === 'string') {
        return [...classes, arg];
      }
      if (Array.isArray(arg)) {
        // Nested if to prevent arrays wihtout a length to continue to the
        // object branch.
        if (arg.length) {
          const inner = classNames(...arg);
          if (inner) {
            return [...classes, inner];
          }
        }
      } else if (argType === 'object') {
        return Object.entries(arg).reduce(
          (objClasses, [cls, condition]) =>
            condition ? [...objClasses, cls] : objClasses,
          []
        );
      }

      return classes;
    }, [])
    .join(' ');
}

/**
 * Change the first character of a string to lowercase.
 *
 * @param {string} str - String to change.
 * @returns {string}
 */
export function lowerFirst(str) {
  return str[0].toLowerCase() + str.slice(1);
}

/**
 * Change the first character of a string to uppercase.
 *
 * @param {string} str - String to change.
 * @returns {string}
 */
export function upperFirst(str) {
  return str[0].toUpperCase() + str.slice(1);
}

/**
 * Convert a string to camelCase.
 *
 * Handles kebab-case, PascalCase, snake_case and spaced words.
 *
 * @param {string} str - String to convert.
 * @returns {string}
 */
export function camelCase(str) {
  const replacer = (match, group1) => group1.toUpperCase();
  return lowerFirst(str).replace(/(?!^)[\s_-]+([a-zA-Z\d])/g, replacer);
}

/**
 * Convert a string to kebab-case.
 *
 * Handles camelCase, PascalCase, snake_case and spaced words.
 *
 * @param {string} str - String to convert.
 * @returns {string}
 */
export function kebabCase(str) {
  // Start by converting camelCase, then convert other separators.
  return str
    .replace(/([a-zA-Z])(?=[A-Z])/g, '$1-')
    .replace(/(?!^)[\s_-]+([a-zA-Z\d])/g, '-$1')
    .toLowerCase();
}

const DATA_VALUE_MAP = {
  true: true,
  false: false,
  null: null,
  NaN,
};

/**
 * Decode an attribute value.
 *
 * @param {*} rawVal - The value to parse.
 * @param {Function} [valueParser] - Custom parser for the value, it will get
 *   the raw string value as a parameter and should return the desired result.
 * @returns {*} Raw or parsed value.
 */
export function decodeAttr(rawVal, valueParser = null) {
  // Handle empty or undefined boolean values
  if (valueParser === Boolean) {
    if (rawVal === '') {
      return true;
    }
    if (rawVal === undefined || rawVal === 'undefined') {
      return false;
    }
  }

  // Empty values are represented with null
  if (rawVal === '' || rawVal === undefined || rawVal === 'undefined') {
    return null;
  }

  // Handle stringified data types like 'false' and 'null'
  if (typeof DATA_VALUE_MAP[rawVal] !== 'undefined') {
    return DATA_VALUE_MAP[rawVal];
  }

  // If a custom parser is passed, let it do the work
  if (valueParser) {
    return valueParser(rawVal);
  }

  // Automatically parse numbers
  if (
    // Abort if starting with zero, to avoid converting something like
    // 0012 to 12.
    (rawVal === '0' || rawVal[0] !== '0') &&
    // Only digits...
    (REGEX.onlyNumber.test(rawVal) ||
      // ...or digits and a single period
      (REGEX.onlyNumberWithPeriods.test(rawVal) &&
        rawVal.match(/\./g).length === 1))
  ) {
    return Number(rawVal);
  }

  return rawVal;
}

/**
 * Encode an attribute value.
 *
 * @param {*} val - Value to encode.
 * @param {Function} [valueEncoder] - Custom encoder for the value, it will get
 *   the raw value as a parameter and should the encoded result.
 * @returns {string}
 */
export function encodeAttr(val, valueEncoder = null) {
  return valueEncoder ? valueEncoder(val) : String(val);
}

/**
 * Format a key used in data attribute helpers.
 *
 * @param {string} key - Raw key name.
 * @returns {string}
 */
function _getDataKey(key) {
  return camelCase(key.replace(REGEX.leadingData, ''));
}

/**
 * Save data on a DOM element data attribute.
 *
 * @param {Element} elem - Element to save data on.
 * @param {string} key - Data key, will result in the attribute `data-[key]`.
 *   Note that the key can be camelCase, but the resulting attribute will
 *   always display as kebab-case in the DOM tree.
 * @param {*} [val] - Value to save, defaults to empty string. Will be
 *   converted to a string as-is by default, so false and null will for
 *   example be converted to 'false' and 'null' respectively.
 * @param {Function} [valueEncoder] - Value encoder. Will get the raw value
 *   as a parameter and should return a string. This should only be required
 *   for custom serialization of complex types like objects that can't be
 *   converted to a useful string automatically.
 */
export function setData(elem, key, val = '', valueEncoder = null) {
  elem.dataset[_getDataKey(key)] = encodeAttr(val, valueEncoder);
}

/**
 * Read data from a DOM element data attribute, automatically parsing some
 * things.
 *
 * @param {Element} elem - Element to read data from.
 * @param {string} key - Data key. Can be kebab-case or the camelCase
 *   equivalent of it; `data-test` requires 'test' as the key while
 *   `data-other-test` can be 'other-test' or 'otherTest'.
 * @param {Function} [valueParser] - Custom parser for the value, it will get
 *   the raw string value as a parameter and should return the desired result.
 * @returns {*} Raw or parsed value.
 */
export function getData(elem, key, valueParser = null) {
  return decodeAttr(elem.dataset[_getDataKey(key)], valueParser);
}

/**
 * Remove a DOM element data attribute.
 *
 * @param {Element} elem - Element to read data from.
 * @param {string} key - Data key. Can be kebab-case or the camelCase
 *   equivalent of it; `data-test` will use 'test' as the key while
 *   `data-other-test` can be 'other-test' or 'otherTest'.
 */
export function removeData(elem, key) {
  delete elem.dataset[_getDataKey(key)];
}

/**
 * Insert a new element as a sibling before a target.
 *
 * @param {Element} target - Node to insert the new element before.
 * @param {Element} newElement - Element to insert.
 */
export function insertBefore(target, newElement) {
  target.parentNode.insertBefore(newElement, target);
}

/**
 * Insert a new element as a sibling after a target.
 *
 * @param {Element} target - Node to insert the new element after.
 * @param {Element} newElement - Element to insert.
 */
export function insertAfter(target, newElement) {
  target.parentNode.insertBefore(newElement, target.nextSibling);
}

/**
 * Create a function that delays invoking until after enough time have elapsed
 * since the last time the function was invoked.
 *
 * Use something like lodash for a more powerful version with options.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @returns {Function} The debounced function.
 */
export function debounce(func, wait) {
  let timeout;
  return function debounced(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func(...args);
    }, wait);
  };
}

/**
 * Check if a key is used to trigger buttons.
 *
 * @param {string} key - Keyboard key name (event.key).
 * @returns {boolean}
 */
export function isButtonActivationKey(key) {
  // 'Spacebar' is for older browsers.
  // https://developer.mozilla.org/en-US/docs/web/api/ui_events/keyboard_event_key_values
  return key === 'Enter' || key === ' ' || key === 'Spacebar';
}

/**
 * Check if an event for click/keyboard is for opening in a new tab/window.
 *
 * @param {MouseEvent|KeyboardEvent} event
 * @returns {boolean}
 */
export function isNewTabEvent(event) {
  // Meta is the command key on mac, used to open in new tab. Ctrl is the
  // Windows equivalent. Shift is used to open in a new window.
  return event.ctrlKey || event.metaKey || event.shiftKey;
}
